<template>
  <div id="ai-services_nlp_ner">
    <ner-entities-dialog
      :visible.sync="showEntitiesDialog"
      :entity-config="entities"
    />
    <service-template
      :class="{'nlp-example-rtl': currentLanguage===$consts.lang.AR}"
      :run="run"
      :run-success="runSuccess"
      :run-error="runError"
      :run-disabled="runDisabled"
      :loading="loading"
      :icon="require('@/assets/images/icons/services/header/NER.svg')"
      service="nlp"
    >
      <template v-slot:header>
        {{ $t('services.namedEntityRecognition.header') }}
      </template>
      <template v-slot:left>
        <input-language
          :step="1"
          :languages="availableLanguages"
          :current-language.sync="currentLanguage"
          :loading="loading"
        />
        <letter-case-radio
          v-if="showStep2"
          :step="2"
          :value.sync="currentMode"
          :loading="loading"
        />
        <example-text
          :step="showStep2 ? 3 : 2"
          :items="textExamples"
          :current-example.sync="currentExample"
          :example-text.sync="exampleText"
          :loading="loading"
        />
      </template>
      <template v-slot:right>
        <generated-result
          :step="showStep2 ? 4 : 3"
          :service-name="$t('services.namedEntityRecognition.header')"
          :loading="loading"
        >
          <div v-if="resultItems">
            <div class="gr-title">
              {{ $t('services.namedEntityRecognition.grTitle') }}
              <i
                class="material-icons"
                @click="openEntitiesDialog"
              >info</i>
            </div>
            <div class="gr-description">
              {{ $t('services.namedEntityRecognition.grDescription') }}
            </div>
            <div class="result">
              <span
                v-for="(item, index) in resultItems"
                :key="index"
                :class="{ 'result-item': true, hasProperty: item.rgbaColor }"
              >
                <el-tooltip
                  placement="top"
                  :disabled="!item.rgbaColor"
                  popper-class="ner-results-popper"
                  @created="(el) => handleTooltipCreated(el, item)"
                >
                  <div
                    slot="content"
                    class="tooltip"
                  >
                    <font-awesome-icon
                      :icon="item.icon"
                      class="icon"
                    />
                    {{ item.label }}
                  </div>
                  <span>
                    &nbsp;
                    <span>
                      <span>{{ item.preContent }}</span>
                      <span :style="{ background: item.rgbaColor }">{{ item.entity }}</span>
                      <span
                        v-if="item.rgbaColor"
                        class="dot"
                        :class="{ arabic: currentLanguage === $consts.lang.AR }"
                        :style="{ background: item.hexColor }"
                      />
                      <span
                        v-if="item.rgbaColor"
                        class="line"
                        :class="{ arabic: currentLanguage === $consts.lang.AR }"
                        :style="{ background: item.hexColor }"
                      />
                    </span>
                    &nbsp;
                  </span>
                </el-tooltip>
              </span>
            </div>
          </div>
        </generated-result>
      </template>
    </service-template>
  </div>
</template>
<script>
import ServiceTemplate from '@/views/pages/AIServices/components/ServiceTemplate/Index.vue';
import InputLanguage from '@/views/pages/AIServices/components/InputLanguage/Index.vue';
import LetterCaseRadio from '@/views/pages/AIServices/components/LetterCaseRadio/Index.vue';
import GeneratedResult from '@/views/pages/AIServices/components/GeneratedResult/Index.vue';
import ExampleText from '@/views/pages/AIServices/components/ExampleText/Index.vue';
import NerEntitiesDialog from '@/views/pages/AIServices/components/NerEntitiesDialog/Index.vue';

export default {
  name: 'NamedEntityRecognition',
  components: {
    NerEntitiesDialog,
    ExampleText,
    GeneratedResult,
    InputLanguage,
    LetterCaseRadio,
    ServiceTemplate,
  },
  data() {
    return {
      showEntitiesDialog: false,
      availableLanguages: [this.$consts.lang.EN, this.$consts.lang.AR],
      currentLanguage: this.$consts.lang.EN,
      currentMode: this.$consts.letterCaseModes.CASE_SENSITIVE,
      entities: this.$consts.nerEntityConfig,
      currentExample: null,
      examples: [],
      exampleText: '',
      result: null,
      loading: false,
    };
  },
  computed: {
    resultItems() {
      if (this.result && this.result.code === 'success') {
        const resultItems = [];

        this.result.word_label.forEach(({ entity, property: prop }) => {
          const propParts = prop.split('-');
          const property = propParts.pop();
          const boundary = propParts.pop();
          const prevItem = resultItems[resultItems.length - 1];
          const isPre = property.endsWith('_pre');

          let resultItem = {
            entity: isPre ? entity.substring(1) : entity,
            property: isPre ? property.replace('_pre', '') : property,
            boundary,
            preContent: isPre ? entity.charAt(0) : '',
          };

          if (prop.startsWith('S-') || prop.startsWith('B-')) {
            resultItems.push(resultItem);
          } else if (
            (prop.startsWith('I-') || prop.startsWith('E-'))
            && prevItem.boundary === 'B'
          ) {
            resultItem = prevItem;
            resultItem.entity = `${resultItem.entity} ${entity}`.trim();
          } else {
            resultItems.push(resultItem);
          }
        });

        return resultItems.map((resultItem, idx) => {
          let configKey = resultItem.property.toLowerCase();

          if (configKey === 'other') {
            configKey = 'miscellaneous';
          }

          const itemConfig = this.$consts.nerEntityConfig[
            configKey
          ] || Object.values(this.$consts.nerEntityConfig).find(({ tag }) => tag.toLowerCase() === resultItem.property.toLowerCase());
          const hexColor = itemConfig?.color;

          return {
            ...resultItem,
            idx,
            hexColor,
            icon: itemConfig?.icon,
            rgbaColor: hexColor
              ? `rgba(${this.$utils.hexToRgb(hexColor).join(',')},0.1)`
              : '',
            label: itemConfig?.label,
          };
        });
      }

      return null;
    },
    textExamples() {
      return (this.examples[this.currentLanguage] || []).map((el, index) => ({
        id: index,
        value: el,
      }));
    },
    runDisabled() {
      return !this.exampleText || !this.currentLanguage;
    },
    showStep2() {
      return this.currentLanguage === this.$consts.lang.EN;
    },
  },
  watch: {
    currentLanguage() {
      this.currentExample = 0;
      this.result = null;
    },
    currentExample() {
      this.result = null;
    },
    currentMode() {
      if (Number.isFinite(this.currentExample)) {
        if (
          this.currentMode === this.$consts.letterCaseModes.CASE_INSENSITIVE
        ) {
          this.exampleText = this.textExamples[
            this.currentExample
          ].value.toLowerCase();
        } else {
          this.exampleText = this.textExamples[this.currentExample].value;
        }
      }
    },
    exampleText() {
      if (this.exampleText) {
        if (
          this.currentMode === this.$consts.letterCaseModes.CASE_INSENSITIVE
        ) {
          this.exampleText = this.exampleText.toLowerCase();
        }
      }
      this.result = null;
    },
  },
  async created() {
    this.examples = await import('@/../public/examples/NLP/namedEntityRecognition.json');
    this.currentExample = 0;
  },
  methods: {
    async run() {
      this.loading = true;

      return this.$api.nlp.getNamedEntityRecognition({
        lang: this.currentLanguage,
        text: this.exampleText,
        mode: this.currentMode,
      });
    },
    runSuccess(result) {
      this.loading = false;
      this.result = result.data;
    },
    runError() {
      this.loading = false;
    },
    handleTooltipCreated(el, item) {
      const { popper } = el.$refs;

      Object.assign(popper.style, { background: item.hexColor, padding: 0 });

      popper.querySelector('[x-arrow]').style.borderTopColor = item.hexColor;
    },
    openEntitiesDialog() {
      this.showEntitiesDialog = true;
    },
  },
};
</script>

<style lang="scss">
.ner-results-popper {
  [x-arrow] {
    &::after {
      content: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.tooltip {
  font-family: 'Montserrat', sans-serif;
  padding: 0 0.1rem;
  border-radius: 0.04rem;
  color: $color_white;
  line-height: 0.24rem;
  display: flex;
  align-items: center;

  .icon {
    margin-right: 0.03rem;
  }
}

#ai-services_nlp_ner {
  background: $background-secondary;

  .gr-title {
    display: flex;
    align-items: center;
    margin-bottom: 0.05rem;
    font-weight: 600;
    font-size: 0.16rem;
    color: $text-primary;

    i {
      font-size: 0.2rem;
      color: #647087;
      margin-left: 0.1rem;
      cursor: pointer;
    }
  }

  .gr-description {
    margin-top: 0.05rem;
    margin-bottom: 0.24rem;
    font-weight: normal;
    font-size: 0.12rem;
    color: $text-primary;
  }

  .result-item {
    margin-top: 0.12rem;
    font-size: 0.14rem;
    line-height: 0.42rem;
    position: relative;

    &.hasProperty {
      cursor: pointer;
      white-space: nowrap;
    }

    .dot {
      position: absolute;
      right: 0;
      top: 0;
      width: 0.05rem;
      height: 0.05rem;
      border-radius: 50%;
      transform: translate(50%, -50%);

      &.arabic {
        left: 0;
        right: auto;
        transform: translate(-50%, -50%);
      }
    }

    .line {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 0.01rem;
      transform: translateX(50%);

      &.arabic {
        left: 0;
        right: auto;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
