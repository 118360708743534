<template>
  <div class="capital-letters-radio">
    <h5 class="step-title">
      <span class="step-title__primary-text">{{ $t('services.step', [step]) }}</span>
      <span class="step-title__secondary-text"> {{ $t('services.capitalLettersRadio.title') }}</span>
    </h5>
    <el-radio-group
      :value="value"
      :disabled="loading"
      @input="onChange"
    >
      <el-radio
        v-for="item in options"
        :key="item.value"
        :label="item.value"
      >
        {{ item.label }}
        <el-tooltip
          v-model="showTooltip[item.value]"
          manual
          effect="grey"
          placement="top"
          popper-class="letter-case-radio-popper"
        >
          <div slot="content">
            {{ item.description }}
          </div>
          <i
            class="material-icons"
            @click="(event) => handleInfoClick(item, event)"
            @mouseleave="() => showTooltip[item.value] = false"
          >info</i>
        </el-tooltip>
      </el-radio>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  name: 'LetterCaseRadio',
  props: {
    value: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      availableOptions: [
        [
          this.$consts.letterCaseModes.CASE_SENSITIVE,
          'Name entities with capital letters (such as "Christopher Columbus") are maintained as capital letters',
        ],
        [
          this.$consts.letterCaseModes.CASE_INSENSITIVE,
          'Capitalized letters in name entities are turned into small letters ("christopher columbus")',
        ],
      ],
      showTooltip: {},
    };
  },
  computed: {
    options() {
      return this.availableOptions.map(([value, description]) => ({
        label: this.$t(`letterCaseModes.${value}`),
        description,
        value,
      }));
    },
  },
  methods: {
    handleInfoClick(item, event) {
      event.preventDefault();
      this.showTooltip[item.value] = true;
    },
    handleTooltipCreated(el, item) {
      const { popper } = el.$refs;

      Object.assign(popper.style, { background: item.hexColor, padding: 0 });

      popper.querySelector('[x-arrow]').style.borderTopColor = item.hexColor;
    },
    onChange(newValue) {
      this.$emit('update:value', newValue);
    },
  },
};
</script>

<style lang="scss">
$grey: #647087;

.letter-case-radio-popper {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.14rem;

  &.is-grey {
    max-width: 2.42rem;
    font-size: 0.10rem;
    font-weight: 500;
    background: $grey;
    color: $color_white;

    [x-arrow] {
      border-top-color: $grey;

      &::after {
        border-top-color: $grey;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.step-title {
  @include steps-title();
}

.capital-letters-radio {
  margin-top: 0.25rem;

  /deep/ .el-radio {
    display: flex;
    margin-right: 0.3rem;
    font-size: 0.14rem;

    &__inner {
      width: 0.2rem;
      height: 0.2rem;

      &::after {
        width: 0.1rem;
        height: 0.1rem;
      }
    }

    &__label {
      font-size: 0.14rem;
      padding-left: 0.1rem;
      i {
        vertical-align: -35%;
      }
    }

    &-group {
      display: flex;
      margin-top: 0.16rem;

      /deep/ .el-radio__label {
        display: flex;
        align-items: center;

        i {
          color: $text-primary-dimmed;
          cursor: pointer;
          font-size: 0.15rem;
          margin-left: 5px;
        }
      }
    }
    &-button {
      width: 100%;
    }
  }

}
</style>
